<template>
    <section id="pricing" style="margin: 0px; padding: 0px;">
        <iframe :src="banners[place ? place : 'bc']" width="620" height="370" frameborder="0" scrolling="no" style=""></iframe>
        <button @click="gotoCheckout(plans[place ? place : 'bc'])" class="btn btn-primary btn-initial btn-rounded"  
            style="margin: 20px;">Upgrade now</button>
    </section>
</template>


<script>

import { loadStripe } from '@stripe/stripe-js';
import { stripe_public, functions } from '@/services/firebaseConfig.js'
import { planes_businesscards } from '@/mixins/stripe.js'
export default {
    props: ['place'],
    middleware: 'auth-check',
    head() { return this.$util.simpleMetaHead() },
    data() {
        return {
            loading: false,
            lugar: this.place,
            planes_businesscards: planes_businesscards,
            getUser: this.$store.state.userProfile,
            annual: true,
            sessionId: '',
            banners: {
                'bc': "https://linkcard.app/banner-upgrade-bc/",
                'stats': "https://linkcard.app/banner-upgrade-bc/",
                'leads': "https://linkcard.app/banner-upgrade-bc/",
                'pas': "https://linkcard.app/banner-upgrade-micro-landings/",
                'lib': "https://linkcard.app/banner-upgrade-link-in-bio/",
            },
            plans: {
                evnt: 'price_1PsO4bES9JaRReUPZHw5E3Gb',
                lib: 'price_1PsNxCES9JaRReUP4CPbQMpG',
                pas: 'price_1PsNsEES9JaRReUPQH5dgyLe',
                bc: 'price_1Pqh3vES9JaRReUP21ymngyl',
                leads: 'price_1Pqh3vES9JaRReUP21ymngyl',
                stats: 'price_1Pqh3vES9JaRReUP21ymngyl',
            }
        }
    },
    methods: {
        async gotoCheckout_client(product) {
            var dis = this
            let price = product.plans[product.plans.length > 1 && this.annual ? 1 : 0]
            this.loading = true

            let stripe = await loadStripe('pk_live_KM0WAadM3l0YbDQtCkvnaSLE00OuTdfw3Q');
            stripe.redirectToCheckout({ mode: 'subscription', lineItems: [{ price: price.plan_id, quantity: 1 }], locale: dis.$store.state.locale, successUrl: 'https://my.linkcard.app/account#success', cancelUrl: 'https://my.linkcard.app/account#plans' }).then(function (result) {
                console.log(result);
                dis.$util.registerStat('payment', { subtype: 'intent', freq: price.type, price: price.price, place: dis.lugar })
            }).catch(function (error) {
                console.error(error);
            });
        },
        async gotoCheckout(price_id){
              var dis=this
              //if(!dis.getUser.stripe_details.stripe_customer_id)return alert('Stripe ID missing. Please refresh. Contact support')
              // let price=product.plans[product.plans.length>1 && this.annual?1:0]
              this.loading=true
              let url=process.env.URL
              const get_stripe_checkout = functions.httpsCallable('get_stripe_checkout');
              await get_stripe_checkout({customer_id:dis.getUser.stripe_details.stripe_customer_id,price_id:price_id,locale:dis.$store.state.locale,success_url:url+'account#success',cancel_url:url+'account#plans'})
              .then(function(result) {
                    dis.$util.registerStat('payment',{subtype:'intent',freq:'evnt',price:price_id,place:dis.lugar})
                    var stripe = Stripe(stripe_public);
                    stripe.redirectToCheckout({sessionId: result.data.id}).catch(error => {console.log(error) })

                    dis.loading=false
              }).catch(error => {
                  console.log(error)
              })
        },
        async gotoCheckout_orig(product) {
            var dis = this
            if (!dis.getUser.stripe_details.stripe_customer_id) return alert('Stripe ID missing. Please refresh. Contact support')
            let price = product.plans[product.plans.length > 1 && this.annual ? 1 : 0]
            this.loading = true
            let url = process.env.URL
            const get_stripe_checkout = functions.httpsCallable('get_stripe_checkout');
            await get_stripe_checkout({ customer_id: dis.getUser.stripe_details.stripe_customer_id, price_id: price.plan_id, locale: dis.$store.state.locale, success_url: url + 'account#success', cancel_url: url + 'account#plans' })
                .then(function (result) {
                    dis.$util.registerStat('payment', { subtype: 'intent', freq: price.type, price: price.price, place: dis.lugar })
                    var stripe = Stripe(stripe_public);
                    stripe.redirectToCheckout({ sessionId: result.data.id }).catch(error => { console.log(error) })

                    dis.loading = false
                }).catch(error => {
                    console.log(error)
                })
        },
    }
}
</script>

<style scoped>
.switch-button {
    text-transform: uppercase;
}

.right-active .switch-option-right span,
.left-active .switch-option-left span {
    color: white !important;
}

#pricing.left .price.mensual,
#pricing.right .price.anual {
    display: none;
}

.pricing-important span {
    display: block;
}

.pricing-important {
    font-size: 12px !important;
}
</style>
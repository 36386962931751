
<template>
  <modal name="modal-planes"
        transition="nice-modal-fade"
        :classes="clases"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="690px"
        height="auto"
        @before-close="beforeClose"
        @before-open="beforeOpen">
        <div style="padding:0px;">
            <div slot="top-right"><button @click="$modal.hide('modal-planes')" class="ngdialog-close"></button></div>
            <planes :place="place" />
        </div>
</modal>
</template>

<script>
import planes from '@/components/planes.vue'
import { planes_businesscards} from '@/mixins/stripe.js'

export default {
    data () {
        return {
            cantClose:true,
            planes:planes_businesscards,
            place:'modal',
            width:this.$util.isMobile()?'90%':1000,
            clases:'ngdialog-content empresa',
            getUser:this.$store.state.userProfile
        }
    },
    methods: {
        beforeClose(){

            if(this.cantClose)this.$util.gotoHOME();
        },
        beforeOpen (event) {
            this.cantClose=event.params.close?true:false
            if(event.params.place)this.place=event.params.place
            if(event.params.user)this.getUser=event.params.user
            if(event.params.planes)this.planes=event.params.planes
        },
    },
    components:{
        planes
    }
}

</script>